import { useTopBannerStore } from "@/stores/useTopBannerStore"
import axios from "axios";
import { useEffect, useState } from "react";
import Image from 'next/image';
import Link from "next/link";


const TopBanner = () => {
  const { updateTopBanner, getTopBanner } = useTopBannerStore();
  const [topBannerData, setTopBannerData] = useState(getTopBanner());

  const render_top_banner = () => {
    const item = topBannerData.data[0];

    if (item === null || item === undefined) {
      return (<></>);
    }

    if (item.desktop_image !== null && item.mobile_image !== null && item.image_alt !== null) {
      return (
        <div>
          <Link href={item.link !== null && item.link.length > 0 ? item.link : '#'}>
            <div className="hidden w-full md:block">
              <Image
                src={item.desktop_image}
                alt={item.image_alt}
                width={1920}
                height={80}
                quality={100}
                className="w-full h-auto aspect-[48/1] object-cover"
              />
            </div>
            <div className="w-full md:hidden">
              <Image
                src={item.mobile_image}
                alt={item.image_alt}
                width={1024}
                height={100}
                quality={100}
                className="object-cover w-full h-9"
              />
            </div>
          </Link>
        </div>
      )
    } else if (item.name !== null) {
      return (
        <div className="w-full text-center text-xs md:text-sm font-poppins text-white py-[15px]">
          <Link href={item.link !== null && item.link.length > 0 ? item.link : '#'}>
            {item.name}
          </Link>
        </div>
      )
    } else {
      return (<></>);
    }
  }

  useEffect(() => {
    const fetchTopBanner = async () => {
      try {
        const response = await axios.get('/api/topbanner')

        if (response.data.status === "ok") {
          topBannerData.update_date = Date.now();

          if (response.data.data.length > 0) {
            topBannerData.data = response.data.data;
          } else {
            topBannerData.data = [];
          }

          updateTopBanner(topBannerData);
          setTopBannerData(topBannerData);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (topBannerData.update_date === null || topBannerData.update_date < Date.now() - 600000 || topBannerData.data === undefined) {
      fetchTopBanner();
    }
  }, [topBannerData, topBannerData.update_date, updateTopBanner])

  if (topBannerData.data !== undefined && topBannerData.data.length > 0 && topBannerData.data[0].name === null && topBannerData.data[0].desktop_image === null && topBannerData.data[0].mobile_image === null) {
    return (<></>);
  }

  return (
    <>
      {topBannerData.data !== undefined && topBannerData.data.length > 0  && (
        <div className="w-full h-auto bg-mooimom">
          {render_top_banner()}
        </div>
      )}
    </>
  )
}

export default TopBanner
